import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { GiKitchenScale, GiFruitBowl } from 'react-icons/gi'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import pizza from '../pizza.jpg'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import zIndex from '@mui/material/styles/zIndex';

const MainPage: React.FC = () => {
    interface FoodInfo {
        idx: number;
        label: string;
        gram: number;
    }
    const foodstuffs: FoodInfo[] = [
        { idx: 0, label: 'キャベツ(1/4個 300g)', gram: 300 },
        { idx: 1, label: 'キャベツ(1/2個 600g)', gram: 600 },
        { idx: 2, label: 'キャベツ(1個 1200g)', gram: 1200 },
        { idx: 3, label: '玉ねぎ(1個 200g)', gram: 200 },
        { idx: 4, label: 'にんじん(1本 180g)', gram: 180 },
        { idx: 5, label: 'じゃがいも(1個 150g)', gram: 150 },
        // { idx: 6, label: 'トマト(1個 180g)', gram: 180 },
        // { idx: 7, label: 'かぼちゃ(1玉 1200g)', gram: 1200 },
        // { idx: 8, label: 'ブロッコリー(1房 200g)', gram: 200 },
        { idx: 6, label: '大根(1本 1000g)', gram: 1000 },
        { idx: 7, label: '白菜(1/4玉 500g)', gram: 500 },
        { idx: 8, label: '白菜(1/2玉 1000g)', gram: 1000 },
        { idx: 9, label: '白菜(1玉 2000g)', gram: 2000 },
        { idx: 10, label: '豆腐(1丁 350g)', gram: 350 },
        { idx: 111, label: 'ピーマン(1個 35g)', gram: 35 },
    ];
    const [gram, setGram] = useState<number | null>(300);
    const [foodinfo, setFoodinfo] = useState<FoodInfo | null>(foodstuffs[1]);

    const [fraction, setFraction] = useState<string | null>('')

    interface normalizeWeight {
        [key: string]: {
            lower: number,
            upper: number
        }
    }
    const normalizeWeight = () => {
        const fraction_rate: normalizeWeight = {
          '0': {
            lower: 0,
            upper: 0.1
          },
          '1/5': {
            lower: 0.1,
            upper: 0.225
          },
          '1/4': {
            lower: 0.225,
            upper: 0.29
          },
          '1/3': {
            lower: 0.29,
            upper: 0.365
          },
          '2/5': {
            lower: 0.365,
            upper: 0.45
          },
          '1/2': {
            lower: 0.45,
            upper: 0.55
          },
          '3/5': {
            lower: 0.55,
            upper: 0.63
          },
          '2/3': {
            lower: 0.63,
            upper: 0.705
          },
          '3/4': {
            lower: 0.705,
            upper: 0.775
          },
          '4/5': {
            lower: 0.775,
            upper: 0.9
          },
          '1': {
            lower: 0.9,
            upper: 1.0
          }
        };
        
        // 画面.グラム÷野菜の重さ
        var normalize = gram! / foodinfo!.gram;
        var normalize_integer = Math.trunc(normalize);
        var normalize_decimal = normalize - normalize_integer;

        // answerがどのlower, upperに対応しているか？
        for (const i in fraction_rate) {
            if (
                normalize_decimal >= fraction_rate[i]['lower'] &&
                normalize_decimal < fraction_rate[i]['upper']
            ) {
            if (normalize_integer > 0) {
                if (i === '0') {
                    setFraction(normalize_integer.toString());
                } else if (i === '1') {
                    setFraction((normalize_integer + 1).toString());
                } else {
                    setFraction(normalize_integer.toString() + 'と' + i);
                }
                } else {
                setFraction(i);
                }
            }
        }
    };

    useEffect(() => {
        normalizeWeight()
    }, [gram, foodinfo])

    return (
        <>
            <Grid container spacing={2} alignItems='center' justifyContent='center' direction='column'>
                <Grid item xs={12}>
                    <img src={pizza} alt='pizza' width='320px'/>
                </Grid>
                <Grid item xs={12}>
                    <p style={{ color: '#888' }}>グラム<span><GiKitchenScale color={'#CCD6A6'}/></span>と食材<span><GiFruitBowl color={'#CCD6A6'}/></span>から重量の目安を計算します</p>
                </Grid>
                <Grid item xs={12}>
                    <Box
                    width={320}
                    height={100}
                    bgcolor='#CCD6A6'
                    alignItems='center'
                    justifyContent='center'
                    display='flex'
                    fontSize={44}
                    fontWeight='bold'
                    letterSpacing={16}
                    >
                        { fraction }
                    </Box>
                </Grid>
                <div style={{width: '300px'}}>
                <Grid item xs={12}>
                        <GiKitchenScale color={'#CCD6A6'} size={36} style={{padding: '5px'}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField  
                        type='number'
                        fullWidth
                        onFocus={(e) => {
                            e.target.select()
                        }}
                        sx={{
                            backgroundColor: '#F4EAD5',
                        }}
                        value={gram}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            console.log(e.target.value)
                            setGram(parseInt(e.target.value))
                        }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                        id='outlined-basic'
                        label='グラム'
                        variant='outlined'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GiFruitBowl color={'#CCD6A6'} size={36} style={{padding: '5px'}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                        <InputLabel>食材</InputLabel>
                            <Select
                                value= {foodinfo?.idx}
                                label='食材'
                                onChange={(event: any) => {
                                    setFoodinfo(foodstuffs[event.target.value])
                                }}
                                sx={{ width: 300,backgroundColor: '#F4EAD5' }}
                                >
                                {foodstuffs.map((data, idx) => 
                                    <MenuItem key={idx} value={idx}>{data.label}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </div>               
            </Grid>
            <Grid container spacing={2} alignItems='center' justifyContent='center' direction='column' sx={{height: '160px'}}>
                <div style={{width: '300px'}}>
                    <Accordion sx={{
                        backgroundColor: '#FFFBE9',
                        marginTop: '16px',
                        zIndex: '9999',
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                            >
                            <Typography sx={{color: '#888888', fontSize: '8px'}}>諸注意と引用について</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{color: '#888888'}}>
                                表示されている重量は目安です。
                                このアプリでは食材を何等分に切れば何グラムなのか目安を簡単に知ることができます。
                                <br/>例えば、キャベツ300gは1玉に対しておおよそ1/4の大きさくらい、
                                キャベツ200gは半玉に対しておおよそ1/3の大きさくらい、
                                キャベツ100gは1/4玉に対しておおよそ1/3であることを自動で計算することができます。
                                <br/>キャベツだけでなく、人参や白菜、玉ねぎなども自動で計算できます。試してみてください！
                                <br/>なお、食材のグラム数は
                                <a href='https://park.ajinomoto.co.jp/recipe/basic/ingredients_bunryou/' target='_blank'>味の素パーク様</a>
                                を引用させていただいております。
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>

            </Grid>
        </>
    )
}

export default MainPage
